import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link, graphql } from "gatsby"

import { SEO } from "../components/SEO"
import { DefaultLayout } from "../layouts/Default"
import { Testimonial } from "../components/Testimonial"
import { Pricing } from "../components/PricingHome"
import { CourseCard, CourseGrid } from "../components/CourseCard"

import { clickEvent } from "../lib/analytics"
import siteData from "../site-data"

// Lead Magnet
// Get a free crash course on building your first React Native app.

const metaTitle = "Learn React Native Online at React Native School"
const metaDescription =
  "Learn React Native from people who use it to make a living. From the fundamentals to managing a production app we help you master all aspects of React Native."

const heroTitle = "Build iOS and Android Apps with React Native"
const heroSubTitle =
  "From the fundamentals to managing production apps React Native School helps you master cross platform development."

const testimonial = {
  text: "Spencer has a great teaching style, and effortlessly conveys the material in a logical manner. It is easy to learn from him and that is a testament to his skill!",
  author: "Nathan L.",
  course: "Master React Navigation v5",
}

const testimonial2 = {
  text: "Amazing fundamentals workshop. I'm just starting with react native and this workshop was very helpful. The Github repo is great as well.",
  author: "Mamoun",
  course: "React Native Fundamentals Workshop",
}

const testimonial3 = {
  text: "This is an incredibly helpful class that can help you build great components. It is highly recommended for those who have never used Storybook before.",
  author: "Amjad O.",
  course: "Building a Component Library with Storybook",
}

const Bio = () => (
  <div className="py-16">
    <div className="container mx-auto px-8 max-w-screen-lg">
      <div className="flex flex-col md:flex-row">
        <div className="text-center mb-6 md:mb-0 self-start md:mr-8 lg:mr-12">
          <StaticImage
            src="../images/spencer-carli.JPG"
            alt="Spencer and his dog Luna"
            className="oject-cover rounded-full w-72 h-72 mb-2 border-2 border-gray-300 shadow-md"
          />
          <p className="text-sm text-gray-600">Spencer and his dog Luna</p>
        </div>
        <div className="prose prose-blue prose-xl md:mt-6 lg:mt-12">
          <p>
            Hey! I'm Spencer Carli. I'm the main author at React Native School.
          </p>
          <p>
            I've been building apps with React Native full-time since it was
            open-sourced in 2015. From small apps to large ones, like the{" "}
            <a
              href="https://twitter.com/spencer_carli/status/962147322745491457"
              target="_blank"
              rel="noreferrer"
              className="font-semibold"
            >
              Official 2018 Winter Olympics App
            </a>
            , I've learned a lot about building high quality React Native apps.
          </p>
        </div>
      </div>
    </div>
  </div>
)

const Hero2 = () => (
  <div className="bg-gradient-to-b from-brand-blue to-brand-blue-darker border-b">
    <div className="pt-8 pb-28">
      <div className="container mx-auto px-8 max-w-screen-lg">
        <p className="pt-8 uppercase text-base font-semibold text-gray-200">
          Learn to
        </p>
        <h1 className="font-bold text-5xl leading-none text-white pt-1">
          {heroTitle}
        </h1>
        <h2 className="pt-6 text-xl font-normal text-white">{heroSubTitle}</h2>

        <div className="mt-8">
          <Link
            to="/classes"
            className="bg-brand-green shadow-md hover:shadow-sm text-white font-bold px-4 uppercase tracking-wide py-5 rounded-md whitespace-nowrap mt-4 md:mt-0"
            onClick={() => {
              clickEvent({
                event_category: "CTA",
                event_label: "Home Explore",
              })
            }}
          >
            Explore Courses
          </Link>
        </div>
      </div>
    </div>
  </div>
)

// const Features = () => {
//   const hStyle = "text-3xl font-semibold text-gray-800"
//   const pStyle = "pt-2 text-gray-600 text-xl font-normal"
//   const imgStyle = "object-cover rounded-md w-auto mb-2 shadow-xl"

//   return (
//     <div className="pt-32 pb-32 border-b bg-gradient-to-b from-gray-50 via-blue-gray-200 to-gray-50">
//       <div className="container mx-auto px-8 max-w-screen-xl">
//         {/* <div className="md:w-1/2 mx-auto mb-8 md:mb-20 w-auto">
//           <h2 className="text-3xl lg:text-4xl font-semibold text-gray-800 border-b-2 text-center pb-2">
//             React Native School Features
//           </h2>
//         </div> */}

//         {/* Courses */}
//         <div className="flex flex-col md:flex-row items-center">
//           <div className="text-center mb-6 md:mb-0 self-start md:mr-8 lg:mr-12">
//             <StaticImage
//               src="../images/course.jpg"
//               alt="Course Screenshot"
//               className={imgStyle}
//             />
//           </div>

//           <div>
//             <h3 className={hStyle}>Comprehensive Courses</h3>
//             <p className={pStyle}>
//               Whether you're looking for a comprehensive course to show you
//               exactly how to build a React Native app or you're looking to learn
//               how to do something specific, the right way, React Native School
//               has courses for you.
//             </p>
//             <div className="mt-10">
//               <Link
//                 to="/classes"
//                 className="px-5 py-3 border border-brand-green rounded-md text-brand-green font-semibold hover:bg-brand-green hover:text-white"
//               >
//                 Browse Courses →
//               </Link>
//             </div>
//           </div>
//         </div>

//         {/* Community */}
//         <div className="flex flex-col md:flex-row-reverse items-center my-32 md:my-48">
//           <div className="text-center mb-6 md:mb-0 self-start md:ml-8 lg:ml-12">
//             <StaticImage
//               src="../images/community.png"
//               alt="Community Screenshot"
//               className={imgStyle}
//             />
//           </div>
//           <div>
//             <h3 className={hStyle}>Vibrant Community</h3>
//             <p className={pStyle}>
//               Sometimes you get stuck or your Google search is failing to find
//               you an answer. React Native School's community is filled with
//               fellow React Native developers willing to help.
//             </p>
//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }

const PricingPitch = () => (
  <div id="pricing">
    <div className="pt-16">
      <div className="container mx-auto px-8 max-w-screen-sm text-center">
        <h3 className="text-3xl font-semibold text-blue-gray-700">
          Choose Your Plan
        </h3>
        <p className="pt-2 text-gray-600 text-xl font-normal">
          Join now and get instant access to everything React Native School has
          to offer.{" "}
          <b>
            If you aren't happy with what you find we'll issue you a full refund
          </b>
          . No hassle, no headache. <i>It's all upside</i>.
        </p>
      </div>
    </div>
    <Pricing />
  </div>
)

const Stat = ({ num = 0, title, numSuffix = "", link }) => (
  <Link to={link}>
    <div className="text-center my-8 px-16 transition-all hover:rotate-3 hover:scale-125">
      <p className="font-semibold text-blue-gray-700 text-3xl">
        {`${num.toLocaleString()}${numSuffix}`}
      </p>
      <p className="font-normal text-blue-gray-600 text-sm">{title}</p>
    </div>
  </Link>
)

const Intro = ({ studentCount, courseCount, tutorialCount, reviewCount }) => (
  <div className="py-16 border-b bg-white">
    <div className="container mx-auto px-16 max-w-screen-lg">
      <div className="flex flex-col-reverse md:flex-row items-center">
        <div className="text-gray-800 text-xl font-normal">
          <p className="mb-6">
            There's no question that React Native is a dominant force in mobile
            development. React Native School is a dedicated resource{" "}
            <span className="font-semibold">
              for React Native users by React Native users
            </span>
            .
          </p>
          <p className="mb-6">
            React Native School has taught over {studentCount.toLocaleString()}{" "}
            developers how to use React Native to build iOS and Android apps.
            Our course catalog covers everything from getting started with React
            Native, deploying it to production, niche topics like localization
            or offline capability, and much more.
          </p>
          <p>
            Your time is valuable and we promise to respect it with our{" "}
            <span className="font-semibold">focused video courses</span>.
          </p>
        </div>

        <div className="ml-0 md:ml-4 hidden md:block border-2 rounded-md border-slate-200">
          <Stat num={studentCount} title="Students" numSuffix="+" />
          <Stat num={courseCount} title="Courses" link="/classes" />
          <Stat num={tutorialCount} title="Tutorials" link="/tutorials" />
          <Stat num={reviewCount} title="Reviews" link="/reviews" />
        </div>
      </div>
    </div>
  </div>
)

const Courses = ({ courses = [], courseCount }) => {
  return (
    <div className="py-16 border-b">
      <div className="container mx-auto px-16 max-w-screen-md">
        <div className="text-gray-800 text-xl font-normal text-center">
          <h2 className="text-3xl font-semibold text-blue-gray-700 mb-4">
            Access {courseCount} React Native Courses
          </h2>

          <p className="mb-8">
            React Native School's course library is a{" "}
            <span className="font-semibold">
              comprehensive, up-to-date, and time efficient
            </span>{" "}
            way to master React Native. No BS. All React Native.
          </p>
        </div>
      </div>

      <CourseGrid>
        {courses.map(({ node }) => (
          <CourseCard
            key={node.slug}
            slug={`/${node.slug}/`}
            title={node.title}
            price={node.price}
            description={node.description}
            updated={node.lastUpdated}
          />
        ))}
      </CourseGrid>

      <div className="container mx-auto max-w-screen-lg justify-center mt-12 flex">
        <Link
          to="/classes"
          className="text-lg px-5 py-3 border border-brand-green rounded-md text-brand-green font-semibold hover:bg-brand-green hover:text-white"
        >
          {`Browse All ${courseCount} Courses →`}
        </Link>
      </div>
    </div>
  )
}

const Community = () => {
  return (
    <div className="py-20 pb-24 border-b bg-white">
      <div className="container mx-auto px-16 max-w-screen-xl text-gray-800 text-xl font-normal">
        <h2 className="text-3xl font-semibold text-blue-gray-700 mb-16 text-center">
          Vibrant React Native Focused Community
        </h2>

        <div className="flex flex-col md:flex-row items-center">
          <div className="mr-0 md:mr-2 mb-4 basis-2/5">
            <p className="mb-4">
              The React Native School Community puts you in touch with a group
              of fellow React Native developers.
            </p>
            <p>
              Whether you're new and stuck with learning or you're advanced and
              building something complex you've got a place to move forward.
            </p>
          </div>

          <StaticImage
            src="../images/community.png"
            alt="Community Screenshot"
            className="shadow-xl basis-3/5"
          />
        </div>
      </div>
    </div>
  )
}

const IndexPage = ({ data }) => {
  return (
    <DefaultLayout>
      <SEO title={metaTitle} description={metaDescription} />
      <div className="bg-gray-50">
        <Hero2 pretext="Learn to" title={heroTitle} subtitle={heroSubTitle} />

        {/* <Features /> */}
        <Intro
          studentCount={siteData.studentCount}
          courseCount={data.allCourses.edges.length}
          tutorialCount={data.blog.edges.length}
          reviewCount={data.reviews.edges.length}
        />

        <Courses
          courseCount={data.allCourses.edges.length}
          courses={data.coursesToDisplay.edges}
        />
        <Testimonial {...testimonial} />

        <Community />

        {/* TODO: Get another testimonial */}
        <Testimonial {...testimonial2} />

        <PricingPitch />

        <Testimonial {...testimonial3} />
        <Bio />
      </div>
    </DefaultLayout>
  )
}

export const query = graphql`
  {
    allCourses: allSanityClass(filter: { visible: { eq: true } }) {
      edges {
        node {
          title
        }
      }
    }

    coursesToDisplay: allSanityClass(
      filter: {
        _id: {
          in: [
            "85b6bfdc-3672-4daf-8768-86738c41982e"
            "60e6b090-f4c5-4a61-be12-750392bdcff1"
            "12c523f3-9c00-4ee6-8eaf-179b4a0756e2"
          ]
        }
      }
    ) {
      edges {
        node {
          title
          description
          lastUpdated
          price
          slug
        }
      }
    }

    blog: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { glob: "**/content/blog/**" }
        frontmatter: { published: { eq: true } }
      }
    ) {
      edges {
        node {
          id
        }
      }
    }

    reviews: allSanityReview(
      sort: { fields: date, order: DESC }
      filter: { text: { nin: ["", null] } }
    ) {
      edges {
        node {
          id
        }
      }
    }
  }
`

export default IndexPage
