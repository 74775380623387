import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import format from "date-fns/format"

export const CourseCard = ({ slug, price, title, description, updated }) => (
  <Link to={slug}>
    <div className="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl group">
      <div>
        <div>
          {/* TODO: Use unique images/logos */}
          <StaticImage
            src="../images/lesson-fallback.png"
            className="object-contain h-48 w-full"
            alt={`${title} logo`}
          />
        </div>
        <div className="p-8 flex flex-col">
          <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold">
            {price === 0 ? "Free Course" : "Premium Course"}
          </div>
          <h2 className="block mt-1 text-lg leading-tight font-medium text-black group-hover:underline flex-grow-0">
            {title}
          </h2>
          <div className="mt-2 flex-1 flex flex-col justify-between">
            <p className="text-gray-600 line-clamp-2">{description}</p>
            <p className="text-gray-500 mt-4 text-sm">
              {`Updated on ${format(new Date(updated), "MMM do, yyyy")}`}
            </p>
          </div>
        </div>
      </div>
    </div>
  </Link>
)

export const CourseGrid = ({ children }) => (
  <div className="container mx-auto p-8 max-w-screen-xl grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
    {children}
  </div>
)
