import React from "react"
import { StaticQuery, graphql } from "gatsby"

import { clickEvent } from "../lib/analytics"
import siteData from "../site-data"

export const PricingNoData = ({ courseCount }) => {
  const BASE_OFFER = {
    // title: "Monthly Membership",
    // price: "19",
    // features: [
    //   "Instant access to all our React Native courses",
    //   "Source code to dozens of React Native apps",
    //   "Private community access",
    // ],
    // purchaseUrl: siteData.monthlyPurchaseUrl,
    // event_label: "Monthly - Home",
    title: "Yearly Membership",
    price: siteData.yearlyPrice,
    pricePeriod: "/ year",
    features: [
      `Instant access to all ${courseCount} React Native courses`,
      "Source code to dozens of React Native apps",
      "Private community access",
    ],
    purchaseUrl: siteData.yearlyPurchaseUrl,
    event_label: "Yearly - Home",
    // borderText: "ANNUAL DISCOUNT",
    // subHeaderText: "Complete Access",
  }

  const UPSELL_OFFER = {
    title: "Lifetime Membership",
    price: siteData.lifetimePrice,
    pricePeriod: undefined,
    features: [
      "Lifetime membership (including all updates)",
      `Instant access to all ${courseCount} React Native courses`,
      "Source code to dozens of React Native apps",
      "Private community access",
    ],
    purchaseUrl: siteData.lifetimePurchaseUrl,
    event_label: "Lifetime - Home",
    borderText: "LIFETIME ACCESS",
    subHeaderText: "Best Value",
  }

  return (
    <div className="py-24 pb-28 border-b border-gray-300">
      <div className="relative z-0">
        {/* <div className="absolute inset-0 h-5/6 bg-gray-900 lg:h-2/3"></div> */}
        <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="relative lg:grid lg:grid-cols-9">
            {/* SECOND */}
            <div className="max-w-lg mx-auto lg:max-w-none lg:mx-0 lg:col-start-5 lg:col-end-9 lg:row-start-1 lg:row-end-4">
              <div className="relative z-10 rounded-lg shadow-xl">
                <div>
                  <div className="pointer-events-none absolute inset-0 rounded-lg border-2 border-teal-400" />
                  <div className="absolute inset-x-0 top-0 transform translate-y-px">
                    <div className="flex justify-center transform -translate-y-1/2">
                      <span className="inline-flex rounded-full bg-teal-400 px-4 py-1 text-sm leading-5 font-semibold tracking-wider uppercase text-white">
                        {UPSELL_OFFER.borderText}
                      </span>
                    </div>
                  </div>
                  <div className="bg-white rounded-t-lg px-6 pt-12 pb-10">
                    <div>
                      <p className="text-center text-lg leading-7 font-semibold font-display text-teal-500 uppercase tracking-widest">
                        {UPSELL_OFFER.subHeaderText}
                      </p>
                      <h2 className="mt-2 text-center text-3xl leading-9 font-semibold font-display text-gray-900 sm:-mx-6">
                        {UPSELL_OFFER.title}
                      </h2>
                      <div className="mt-4 flex items-center justify-center font-display">
                        {/* <span className="-ml-8 text-right text-2xl leading-8 font-semibold text-gray-400 tracking-wide line-through sm:text-3xl sm:leading-9">
                        ${UPSELL_OFFER.priceCross}
                      </span> */}
                        <span className="px-3 flex items-start text-6xl leading-none tracking-tight font-medium text-gray-900 sm:text-7xl">
                          <span className="mt-2 mr-1 text-4xl leading-none sm:text-5xl">
                            $
                          </span>
                          <span>{UPSELL_OFFER.price}</span>
                        </span>
                        <span className="text-lg leading-8 font-semibold self-end text-gray-400 tracking-tight">
                          {UPSELL_OFFER.pricePeriod}
                        </span>

                        {/* <span className="text-2xl leading-8 font-semibold text-gray-400 tracking-wide sm:text-3xl sm:leading-9">
                        USD
                      </span> */}
                      </div>
                    </div>
                  </div>
                  <div className="border-t-2 border-gray-100 rounded-b-lg pt-10 pb-8 px-6 bg-gray-100 sm:px-10 sm:py-10">
                    <ul>
                      {UPSELL_OFFER.features.map(feature => (
                        <li className="flex items-start pt-3" key={feature}>
                          <div className="flex-shrink-0">
                            <svg
                              className="h-6 w-6 text-teal-500"
                              stroke="currentColor"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 13l4 4L19 7"
                              />
                            </svg>
                          </div>
                          <p className="ml-3 text-base leading-6 font-medium text-gray-500">
                            {feature}
                          </p>
                        </li>
                      ))}
                    </ul>
                    <div className="mt-10">
                      <div className="rounded-lg shadow-md">
                        <a
                          href={UPSELL_OFFER.purchaseUrl}
                          className="block w-full text-center rounded-lg bg-teal-400 px-6 py-4 text-xl leading-6 font-semibold font-display text-white hover:bg-teal-500 focus:outline-none focus:shadow-outline transition ease-in-out duration-150"
                          target="_blank"
                          rel="noreferrer"
                          onClick={() => {
                            clickEvent({
                              event_category: "begin_checkout",
                              event_label: UPSELL_OFFER.event_label,
                            })
                          }}
                        >
                          Join Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* FIRST */}
            <div className="mt-6 mx-auto max-w-md lg:m-0 lg:max-w-none lg:col-start-2 lg:col-end-5 lg:row-start-2 lg:row-end-3">
              <div className="h-full flex flex-col rounded-lg shadow-lg overflow-hidden lg:rounded-none lg:rounded-l-lg">
                <div className="flex-1 flex flex-col">
                  <div className="bg-white px-6 pt-12 pb-10">
                    <div>
                      <h2 className="mt-2 text-center text-3xl leading-9 font-semibold font-display text-gray-900">
                        {BASE_OFFER.title}
                      </h2>
                      <div className="mt-4 flex items-center justify-center font-display">
                        {/* <span className="-ml-8 text-right text-2xl leading-8 font-semibold text-gray-400 tracking-wide line-through sm:text-3xl sm:leading-9">
                        ${BASE_OFFER.priceCross}
                      </span> */}
                        <span className="px-3 flex items-start text-6xl leading-none tracking-tight font-medium text-gray-900 sm:text-7xl">
                          <span className="px-3 flex items-start text-6xl leading-none tracking-tight font-medium text-gray-900 sm:text-7xl">
                            <span className="mt-2 mr-1 text-4xl leading-none sm:text-5xl">
                              $
                            </span>
                            <span>{BASE_OFFER.price}</span>
                          </span>
                          <span className="text-lg leading-8 font-semibold self-end text-gray-400 tracking-tight">
                            {BASE_OFFER.pricePeriod}
                          </span>
                          {/* <span>{BASE_OFFER.price}</span> */}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="flex-1 flex flex-col justify-between border-t-2 border-gray-100 p-6 bg-gray-100 sm:p-10 lg:p-6 xl:p-10">
                    <ul>
                      {BASE_OFFER.features.map(feature => (
                        <li className="flex items-start pt-3" key={feature}>
                          <div className="flex-shrink-0">
                            <svg
                              className="h-6 w-6 text-teal-500"
                              stroke="currentColor"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 13l4 4L19 7"
                              />
                            </svg>
                          </div>
                          <p className="ml-3 text-base leading-6 font-medium text-gray-500">
                            {feature}
                          </p>
                        </li>
                      ))}
                    </ul>
                    <div className="mt-8">
                      <div className="rounded-lg shadow-md">
                        <a
                          href={BASE_OFFER.purchaseUrl}
                          className="block w-full text-center rounded-lg bg-white px-6 py-3 text-base leading-6 font-semibold font-display text-teal-600 hover:text-teal-500 focus:outline-none focus:shadow-outline transition ease-in-out duration-150"
                          target="_blank"
                          rel="noreferrer"
                          onClick={() => {
                            clickEvent({
                              event_category: "begin_checkout",
                              event_label: BASE_OFFER.event_label,
                            })
                          }}
                        >
                          Sign Up
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <p className="text-center mt-4 text-sm text-gray-500 italic">
        100% money back guarantee
      </p>
    </div>
  )
}

const query = graphql`
  {
    allSanityClass(
      sort: { fields: lastUpdated, order: DESC }
      filter: { visible: { eq: true } }
    ) {
      edges {
        node {
          title
          slug
        }
      }
    }
  }
`

export const Pricing = () => {
  return (
    <StaticQuery
      query={query}
      render={data => (
        <PricingNoData courseCount={data.allSanityClass.edges.length} />
      )}
    />
  )
}
